import React, { useEffect, useState } from 'react';
import Axios from '../../services/axios';
import { useCourse } from '../../context/useCourseContext';
import MarkDownView from '../MarkDownView';
import { ScrollArea } from '../ui/scroll-area';
import { Separator } from '../ui/separator';
import { Loading } from './Loading';
import YoutubeVids from './YoutubeVids';

function Topic({ topic }) {
  const { course, expanded } = useCourse();
  const [content, setContent] = useState('');
  const [videoIds, setVideoIds] = useState([]);
  const [loading, setLoading] = useState(false);

  const getTopic = () => {
    setLoading(true);
    setContent('Please reload');
    const courseData = {
      topic,
      ...course,
    };
    Axios.post(`/course/topic/${course._id}`, { ...courseData })
      .then((res) => {
        setContent(res.data.data.markdownText);
        setVideoIds(res.data.data.suggestedVideos.map((link) => link.split('/').pop()));
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getTopic();
    // eslint-disable-next-line
  }, [topic]);

  if (loading) return <Loading />;

  return (
    <ScrollArea className="h-full px-3 -mx-1 transition-all duration-300">
      <div className="flex-1 mt-1 pt-1 px-4 overflow-hidden bg-muted   rounded-lg h-full">
        <div className="mb-10">
          <YoutubeVids videoIds={videoIds} />
          <Separator />
          <MarkDownView size="default">{content}</MarkDownView>
        </div>
      </div>
    </ScrollArea>
  );
}

export default Topic;
