import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import { NotebookPen, Sparkles, Boxes, ChevronsRight, Clock, Edit, Hammer } from 'lucide-react';
import { Button } from './ui/button';
import { Link } from 'react-router-dom';
import { timeAgo } from '../services';
import { useCourse } from '../context/useCourseContext';

function CourseCard({ course, viewed }) {
  const lastSeen = timeAgo(viewed);
  const { setCourse, clearSelectedTopic, setSelectedToggle } = useCourse();

  console.log(course.members);

  const cardButtons = [
    { icon: NotebookPen, text: 'Doc chat', toggle: 'docchat' },
    { icon: Boxes, text: 'Flash Cards', toggle: 'flashcard' },
    { icon: Sparkles, text: 'Summary', toggle: 'summary' },
  ];

  return (
    <Card className="relative bg-gradient-to-br from-muted/40 to-muted/20 h-full hover:shadow-lg transition-all duration-300 overflow-hidden group backdrop-blur-2xl">
      <Link to={`/courses/${course?.title}?id=${course?._id}`} className="block">
        <CardHeader className="relative p-6 bg-gradient-to-br from-orange-50 to-purple-100 dark:from-slate-900 dark:to-slate-800 overflow-hidden">
          {/* Gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-r from-blue-100/20 to-purple-100/20 dark:from-blue-900/20 dark:to-purple-900/20" />

          {/* Content container */}
          <div className="relative z-10 space-y-4">
            {/* Title section */}
            <CardTitle className="sm:text-xl font-bold line-clamp-2 bg-gradient-to-r from-slate-900 to-slate-700 dark:from-slate-100 dark:to-slate-300 bg-clip-text text-transparent">
              {course?.title}
            </CardTitle>

            <div className="grid gap-2 text-xs text-slate-600 dark:text-slate-300">
              {/* Author row */}
              <div className="flex items-center gap-2">
                <Edit className="w-4 h-4 flex-shrink-0" />
                <span className="text-slate-500 dark:text-slate-400">Curator:</span>
                <span className="font-medium truncate">{course?.author}</span>
              </div>

              {/* Last viewed row - conditional */}
              {viewed && (
                <div className="flex items-center gap-2">
                  <Clock className="w-4 h-4 flex-shrink-0" />
                  <span className="text-slate-500 dark:text-slate-400">Viewed {lastSeen}</span>
                </div>
              )}

              {/* Stats row */}
              <div className="flex items-center gap-2 text-slate-500 dark:text-slate-400">
                <Hammer className="size-4" />
                <div>Updated: {new Date(course?.updatedAt).toLocaleDateString()}</div>
              </div>
            </div>
          </div>
        </CardHeader>
      </Link>
      {/* Card Content */}
      <CardContent className="px-4 space-y-2 relative z-10 pt-2 ">
        <div className="flex items-center">
          {course.members.slice(0, 5).map((avatar, index) => (
            <img
              key={index}
              src={avatar.imageUrl}
              alt="User avatar"
              className="size-8 rounded-full border-2 border-muted"
              style={{ marginLeft: index === 0 ? 0 : '-10px', zIndex: 5 - index }}
            />
          ))}
          {course.members.length > 3 && (
            <span className="text-sm text-muted-foreground ml-2">
              +{course.members.length - 3} more
            </span>
          )}
        </div>

        <div>
          {cardButtons.map((button, index) => (
            <Button
              key={index}
              className="w-full justify-start text-foreground bg-zinc-100 dark:bg-zinc-900 hover:bg-zinc-200 dark:hover:bg-zinc-600 transition-colors duration-200 rounded-lg mb-1"
              onClick={() => {
                setCourse(course);
                clearSelectedTopic();
                setSelectedToggle(button.toggle);
              }}
              asChild>
              <Link to={`/courses/${course?.title}?id=${course?._id}`}>
                <button.icon size={18} className="mr-2" />
                {button.text}
              </Link>
            </Button>
          ))}
        </div>
        <Button
          variant="outline"
          className="w-full justify-between bg-brand text-background hover:bg-brand-hover hover:text-primary-foreground transition-colors duration-200 rounded-lg"
          onClick={() => {
            clearSelectedTopic();
            setCourse(course);
          }}
          asChild>
          <Link to={`/courses/${course?.title}?id=${course?._id}`}>
            <span className="font-semibold">Explore Course</span>
            <ChevronsRight
              size={18}
              className="ml-2 transition-transform duration-200 group-hover:translate-x-1"
            />
          </Link>
        </Button>
      </CardContent>
    </Card>
  );
}

export default CourseCard;
