import { ClerkProvider, SignedIn, SignedOut } from '@clerk/clerk-react';
import { BrowserRouter } from 'react-router-dom';
import { PublicRoutes } from './pages/Pages';
// import ReactGA from 'react-ga4';

import SignIn from './pages/SignIn';
import Layout from './layouts/layout';
import Pages from './pages/Pages';

const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

// ReactGA.initialize('G-HEVSZKBZXD');
// ReactGA.send({ hitType: 'pageview', page: window.location.pathname });

function App() {
  return (
    <>
      <ClerkProvider publishableKey={PUBLISHABLE_KEY}>
        <BrowserRouter>
          {/* Protected routes */}
          <SignedIn>
            <Layout>
              <Pages />
            </Layout>
          </SignedIn>
          {/* Public routes */}

          <PublicRoutes />
          {/* Welcome route */}
          <SignedOut>
            <SignIn />
          </SignedOut>
        </BrowserRouter>
      </ClerkProvider>
    </>
  );
}

export default App;
