import { ToggleGroup, ToggleGroupItem } from '../ui/toggle-group';
import { useCourse } from '../../context/useCourseContext';
import { useUser } from '@clerk/clerk-react';
import {
  BookOpenText,
  ScanEye,
  Sparkles,
  MessagesSquare,
  Boxes,
  ChevronLeft,
  ChevronRight,
  Clipboard,
} from 'lucide-react';
import Settings from './Settings';

function Navigation() {
  const {
    course,
    selectedToggle,
    setSelectedToggle,
    setSelectedTopic,
    isAdmin,
    expanded,
    setExpanded,
  } = useCourse();
  const { user } = useUser();

  return (
    <div className="sticky top-0 flex items-center justify-between border-b-2 w-full z-10 py-1 gap-1 px-1">
      <div
        className={`flex items-center justify-start gap-2 ${
          expanded ? 'md:w-3/12' : 'w-fit'
        }  p-0`}>
        <button className="bg-brand/30 rounded-lg p-2" onClick={() => setExpanded(!expanded)}>
          {expanded ? <ChevronRight size={18} /> : <ChevronLeft size={18} />}
        </button>
        {expanded && (
          <>
            <h2 className="font-semibold text-base text-center text-brand hidden md:block">
              Course outline
            </h2>
          </>
        )}
      </div>

      <div
        className={`${
          !expanded ? 'w-full' : 'w-full md:w-9/12'
        } flex items-center justify-between`}>
        <ToggleGroup
          variant="outline"
          size={'sm'}
          type="single"
          value={selectedToggle}
          className={`flex-grow grid grid-cols-6 gap-2`}
          onValueChange={(value) => {
            setSelectedTopic(null);
            setSelectedToggle(value);
          }}>
          {[
            { value: 'docchat', label: 'AI Chat', icon: Sparkles },
            { value: 'community', label: 'Community', icon: MessagesSquare },
            { value: 'flashcard', label: 'FlashCards', icon: BookOpenText },
            { value: 'quiz', label: 'Practice', icon: Clipboard },
            { value: 'summary', label: 'Glossary', icon: ScanEye },
            { value: 'resources', label: 'Resources', icon: Boxes },
          ].map(({ value, label, icon: Icon, bg, disabled }) => (
            <ToggleGroupItem
              key={value}
              value={value}
              disabled={disabled}
              className={`p-0 ${bg} rounded-md text-sm bg-brand transition-colors duration-300`}>
              <Icon size={18} className="md:mr-1" />
              <span className="hidden md:block">{label}</span>
            </ToggleGroupItem>
          ))}
        </ToggleGroup>
        {isAdmin && <Settings course={course} />}
      </div>
    </div>
  );
}

export default Navigation;
