import React, { useState, createContext, useContext, useCallback, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { axios } from '../services';

const CourseContext = createContext();

export const CourseContextProvider = ({ children }) => {
  const { user } = useUser();
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedToggle, setSelectedToggle] = useState('docchat');
  const [expanded, setExpanded] = useState(true);
  const [course, setCourseState] = useState();
  const [courses, setCourses] = useState([]);

  const getCourses = async () => {
    try {
      const response = await axios.get(`/course/all/${user?.id}`);
      setCourses(response?.data?.data || []);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCourses();
  }, [user]);

  const [fileLinks, setFileLinks] = useState({
    syllabus: '',
    resources: '',
  });

  const handleDeleteTopic = async (topic) => {
    try {
      await axios.delete(`/course/topic/${course._id}/${encodeURIComponent(topic)}`);
      alert('Topic successfully deleted.');
    } catch (error) {
      alert('Error Deleting: ', error);
    }
  };

  const updateCourse = async (updatedCourse) => {
    try {
      await axios.patch(`/course/${course._id}`, updatedCourse);
    } catch (error) {
      console.log(error);
    }
  };

  const getKeynotes = async (courseId, title) => {
    try {
      const course = await axios.patch(`/course/keynotes/${courseId}/${title}`);
      setCourse(course.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileSelect =
    (field) =>
    async ({ title, link }) => {
      setFileLinks((prevLinks) => ({
        ...prevLinks,
        [field]: link,
      }));

      // Update the backend
      try {
        await axios.patch(`/course/${course._id}`, { [field]: link });
      } catch (error) {
        console.error(`Error updating ${field}:`, error);
      }
    };

  const setCourse = async (course) => {
    setCourseState(course);
  };

  const clearSelectedTopic = useCallback(() => {
    setSelectedTopic(null);
  }, []);

  const value = {
    isAdmin: user?.id === course?.userId,
    user,
    course,
    setCourse,
    courses,
    getCourses,
    updateCourse,
    getKeynotes,
    expanded,
    setExpanded,
    handleDeleteTopic,
    selectedTopic,
    setSelectedTopic,
    selectedToggle,
    setSelectedToggle,
    clearSelectedTopic,
    handleFileSelect,
    fileLinks,
  };

  return <CourseContext.Provider value={value}>{children}</CourseContext.Provider>;
};

export const useCourse = () => useContext(CourseContext);
