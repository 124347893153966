import { PanelRight, Linkedin, Plus, Inbox } from 'lucide-react';
import { createContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Separator } from '../components/ui/separator';
import { UserInfo } from './components';
import { Button } from '../components/ui/button';
import { CreateCourse } from '../components/home';
import { Dialog, DialogTrigger, DialogContent } from '../components/ui/dialog';

import logo from '../assets/images/teal_icon.png';

export const SidebarContext = createContext();

export default function SideBar({ children }) {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const value = {
    expanded,
    pathname: location.pathname,
  };

  return (
    <SidebarContext.Provider value={value}>
      <aside className={`sticky top-0 over transition-all z-50 ${expanded ? 'w-60' : 'w-12'}`}>
        <nav className="h-full flex flex-col border-r border-gray-500 shadow-sm items-center">
          <div
            className={`w-full px-2 py-1 flex justify-between gap-1 items-center ${
              !expanded && 'flex-col'
            }`}>
            <div>
              <Link to="/" className="flex items-center gap-2">
                <img
                  src={logo}
                  style={{
                    height: '32px',
                    width: '32px',
                    borderRadius: 8,
                  }}
                  alt="logo"
                />
                {expanded && (
                  <h2
                    className="font-thin text-xl text-pastel-green dark:text-pastel-white"
                    style={{
                      fontFamily: 'Finger Paint, serif',
                    }}>
                    Brogrammers
                  </h2>
                )}
              </Link>
            </div>

            <button
              onClick={() => setExpanded((curr) => !curr)}
              className="rounded-md hover:bg-muted p-2 items-center">
              <PanelRight className="size-4" />
            </button>
          </div>

          <Separator className="w-8" />

          <div className="flex-1 w-full mt-4">
            <ul className="w-full px-1 space-y-1 h-full">{children}</ul>
          </div>

          <div className={`flex flex-col items-center text-muted-foreground`}>
            <div
              className={`flex items-center ${
                expanded ? 'justify-around' : 'justify-center flex-col'
              }`}>
              <Dialog>
                <DialogTrigger>
                  <Button size="icon" variant="ghost">
                    <Plus className="size-4" />
                  </Button>
                </DialogTrigger>
                <DialogContent className="max-w-4xl">
                  <CreateCourse />
                </DialogContent>
              </Dialog>

              <Button size="icon" variant="ghost">
                <Inbox className="size-4" />
              </Button>
              <Button size="icon" variant="ghost" asChild className="mb-1">
                <a href="https://x.com/Brogrammer_ai" target="_blank" rel="noopener noreferrer">
                  <svg
                    className="size-3  fill-current"
                    viewBox="0 0 1200 1227"
                    xmlns="http://www.w3.org/2000/svg">
                    <path d="M714.163 519.284L1160.89 0H1055.03L667.137 450.887L357.328 0H0L468.492 681.821L0 1226.37H105.866L515.491 750.218L842.672 1226.37H1200L714.137 519.284H714.163ZM569.165 687.828L521.697 619.934L144.011 79.6944H306.615L611.412 515.685L658.88 583.579L1055.08 1150.3H892.476L569.165 687.854V687.828Z"></path>
                  </svg>
                </a>
              </Button>
            </div>
            <UserInfo expanded={expanded} />
          </div>
        </nav>
      </aside>
    </SidebarContext.Provider>
  );
}
